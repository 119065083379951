import React, { useState, useEffect } from 'react';
import Web3 from 'web3';

function GasFeeTracker() {
  const [gasFee, setGasFee] = useState(null);

  useEffect(() => {
    const fetchGasFee = async () => {
      try {
        const provider = window.ethereum;
        if (provider) {
          // Create Web3 instance using the Ethereum provider
          const web3 = new Web3(provider);
          // Fetch gas price
          const gasPrice = await web3.eth.getGasPrice();
          // Convert gas price to Gwei
          const gasPriceInGwei = web3.utils.fromWei(gasPrice, 'gwei');
          // Update gas fee state
          setGasFee(parseInt(gasPriceInGwei));
        }
      } catch (error) {
        console.error('Failed to fetch gas fee:', error);
      }
    };

    // Fetch gas fee initially
    fetchGasFee();

    // Listen for network changes
    const handleNetworkChange = () => {
      // When the network changes, refetch the gas fee
      fetchGasFee();
    };

    // Add event listener for network changes
    window.ethereum.on('chainChanged', handleNetworkChange);

    // Cleanup function to remove event listener
    return () => {
      window.ethereum.removeListener('chainChanged', handleNetworkChange);
    };
  }, []);

  return (
    <div className="gas-fee-container" style={{ cursor: 'default' }}>
      {gasFee !== null && (
        <p className="gas-fee-text">{gasFee} Gwei</p>
      )}
    </div>
  );
}

export default GasFeeTracker;
