function ConnectWallet({ connectWalletHandler, walletAddress, isTopRight }) {
  return (
    <div className={isTopRight ? "connect-button top-right" : "connect-button"}>
      {!walletAddress ? (
        <button onClick={connectWalletHandler}>Connect Wallet</button>
      ) : (
        <div style={{ fontSize: '70%' }}>{walletAddress}</div>
      )}
    </div>
  );
}

export default ConnectWallet;