import React, { useState, useEffect } from 'react';
import PiGasPumpLight from './PiGasPumpLight';
import GasFeeTracker from './GasFeeTracker';
import { FaTwitter, FaTelegramPlane, FaInstagram, FaYoutube, FaRedditAlien } from 'react-icons/fa';
import './FooterComponent.css';

function FooterComponent() {
  const [network, setNetwork] = useState(null);
  const [networkChecked, setNetworkChecked] = useState(false);

  useEffect(() => {
    const checkNetwork = async () => {
      try {
        const provider = window.ethereum;
        if (provider) {
          const networkId = await provider.request({ method: 'net_version' });
          setNetwork(networkId === '1' ? 'Ethereum Mainnet' : 'Unknown Network');
          setNetworkChecked(true);
        }
      } catch (error) {
        console.error('Error fetching network:', error);
      }
    };

    checkNetwork();
  }, []);

  return (
    <footer className="footer">
      <div className="content-container">
        <div className="gas-container">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PiGasPumpLight />
            {networkChecked && <GasFeeTracker network={network} />}
          </div>
        </div>
        <div className="social-icons">
          <a href="https://twitter.com/tradersdex" target="_blank" rel="noopener noreferrer">
            <FaTwitter size={15} color="white" />
          </a>
          <a href="https://t.me/tradersdexofficial" target="_blank" rel="noopener noreferrer">
            <FaTelegramPlane size={15} color="white" />
          </a>
          <a href="https://www.instagram.com/tradersdex/" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={15} color="white" />
          </a>
          <a href="https://www.youtube.com/@TradersdexOfficial" target="_blank" rel="noopener noreferrer">
            <FaYoutube size={15} color="white" />
          </a>
          <a href="https://www.reddit.com/r/Tradersdex/" target="_blank" rel="noopener noreferrer">
            <FaRedditAlien size={15} color="white" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default FooterComponent;