import React from 'react';
import logo from './assets/logo.png';
import ConnectWallet from './ConnectWallet';
import './HeaderComponent.css'; // Import CSS file for styling

function HeaderComponent({ setAction, connectWalletHandler, walletAddress }) {
  // Function to shorten the wallet address for display
  const shortenAddress = (address) => {
    if (!address) return '';
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  };

  // Function to handle logo click event
  const handleLogoClick = () => {
    setAction('home');
  };

  return (
    <header className="header">
      <nav className="nav">
        {/* Replace the hamburger icon with your logo */}
        <img src={logo} alt="Logo" className="logo" onClick={handleLogoClick} />
        {/* Add any other content as necessary */}
      </nav>
      <div className="wallet-container">
        {walletAddress ? (
          <div className="wallet-info">{shortenAddress(walletAddress)}</div>
        ) : (
          <ConnectWallet connectWalletHandler={connectWalletHandler} />
        )}
      </div>
    </header>
  );
}

export default HeaderComponent;