import React from 'react';
import { FaGasPump } from 'react-icons/fa'; // Assuming gas pump icon from Font Awesome

function PiGasPumpLight() {
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <FaGasPump size={12} color="white" />
    </div>
  );
}

export default PiGasPumpLight;