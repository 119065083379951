import React, { useState, useEffect } from 'react';
import './App.css';
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import Web3 from 'web3';
import HomeComponent from './components/HomeComponent';

function App() {
  const [web3, setWeb3] = useState(null);
  const [action, setAction] = useState('home');
  const [walletAddress, setWalletAddress] = useState('');

  useEffect(() => {
    const initWeb3 = () => {
      if (window.ethereum) {
        window.ethereum.setMaxListeners(200);
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
        return web3Instance;
      }
      return null;
    };

    const web3Instance = initWeb3();

    const handleAccountsChanged = (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
        setWalletAddress('');
      } else {
        setWalletAddress(accounts[0]);
      }
    };

    if (web3Instance && window.ethereum) {
      window.ethereum
        .request({ method: 'eth_accounts' })
        .then(handleAccountsChanged)
        .catch((err) => {
          console.error(err);
        });

      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      }
    };
  }, []);

  useEffect(() => {
    const checkWalletConnection = async () => {
      if (web3 && window.ethereum) {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_accounts' });
          if (accounts.length > 0) {
            setWalletAddress(accounts[0]);
          }
        } catch (error) {
          console.error('Failed to check wallet connection:', error);
        }
      }
    };

    checkWalletConnection();
  }, [web3]);

  const connectWalletHandler = async () => {
    // Check if MetaMask is available
    if (web3 && window.ethereum) {
      try {
        // Request the user's Ethereum accounts
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        if (accounts.length > 0) {
            setWalletAddress(accounts[0]);
        }
      } catch (error) {
        console.error('Failed to connect to the wallet:', error);
      }
    } else {
      // If MetaMask is not available, use deep linking to prompt the user to install MetaMask
      const metamaskAppLink = 'https://metamask.app.link/dapp/app.tradersdex.com';
      window.location.href = metamaskAppLink;
    }
  };

  function RenderComponent({ action }) {
    let contentComponent;
  
    switch (action) {
      case 'home':
        contentComponent = <HomeComponent setAction={setAction} />;
        break;
      default:
        contentComponent = null;
    }
  
    return (
      <div className="centered">
        {contentComponent}
        {action !== 'home'}
      </div>
    );
  }

  return (
    <div className="App">
      <HeaderComponent setAction={setAction} walletAddress={walletAddress} connectWalletHandler={connectWalletHandler} />
      <RenderComponent action={action} />
      <FooterComponent />
    </div>
  );
}

export default App;