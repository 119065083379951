import React, { useState, useEffect, useCallback } from 'react';
import './HomeComponent.css';

function HomeComponent({ setAction }) {
    const [walletConnected, setWalletConnected] = useState(false);
    const [currentNetwork, setCurrentNetwork] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    const checkWalletConnection = useCallback(async () => {
        try {
            // Check if MetaMask is installed
            if (window.ethereum) {
                // Check if the user is connected
                const accounts = await window.ethereum.request({ method: 'eth_accounts' });
                if (accounts.length > 0) {
                    // If the user is connected, update state
                    setWalletConnected(true);
                    console.log("Connected with MetaMask:", accounts[0]);
                } else {
                    setWalletConnected(false);
                }
                // Get the current network ID
                const networkId = await window.ethereum.request({ method: 'net_version' });
                setCurrentNetwork(networkId);
                console.log("Current network ID:", networkId);
            }
        } catch (error) {
            console.error("Error checking wallet connection:", error);
        }
    }, []);

    const handleConnectWallet = async () => {
      try {
          // Check if MetaMask is installed
          if (window.ethereum) {
              // Request the user to connect their MetaMask wallet
              const result = await window.ethereum.request({ method: 'eth_requestAccounts' });
              
              // Check if the result is successful by checking the length
              if (result.length > 0) {
                  setWalletConnected(true);
                  // Also update the current network
                  const networkId = await window.ethereum.request({ method: 'net_version' });
                  setCurrentNetwork(networkId);
              }
  
              // Double-check wallet connection
              await checkWalletConnection();
              
              // Debug log
              console.log("Wallet connection attempted. Wallet connected:", walletConnected);
          } else {
              // If MetaMask is not installed, prompt the user to install MetaMask
              const metamaskAppLink = 'https://metamask.app.link/dapp/app.tradersdex.com';
              window.location.href = metamaskAppLink;
          }
      } catch (error) {
          console.error("Error connecting to wallet:", error);
      }
  };

    const handleFreeAirdrop = () => {
        window.location.href = 'https://gleam.io/rpLNn/tradersdex-airdrop-';
    };

    useEffect(() => {
        const handleNetworkChange = async (chainId) => {
            console.log("Network changed to:", chainId);
            setCurrentNetwork(chainId);
            // After network change, check wallet connection again
            await checkWalletConnection();
        };

        // Initial check for wallet connection when the component mounts
        checkWalletConnection();

        // Add event listener for network changes if window.ethereum exists
        if (window.ethereum) {
            window.ethereum.on('chainChanged', handleNetworkChange);
        }

        // Clean up event listener when component unmounts
        return () => {
            if (window.ethereum) {
                window.ethereum.removeListener('chainChanged', handleNetworkChange);
            }
        };
    }, [checkWalletConnection]);

    // Check if the application is accessed from a mobile device
    useEffect(() => {
        const userAgent = navigator.userAgent;
        const isMobileDevice = /Mobi|Android|iPhone|iPad/i.test(userAgent);
        setIsMobile(isMobileDevice);
    }, []);

    return (
        <div className="home-container">
            <h1 className="home-title">Welcome to Tradersdex</h1>
            {isMobile ? (
                // Rendering logic for mobile devices
                walletConnected ? (
                    // Show Free Airdrop button if wallet is connected on mobile
                    <button className="centered-button" onClick={handleFreeAirdrop}>Free Airdrop</button>
                ) : (
                    // Show Connect Wallet button if wallet is not connected on mobile
                    <button className="centered-button" onClick={handleConnectWallet}>Connect Wallet</button>
                )
            ) : (
                // Rendering logic for web version (no change)
                walletConnected && currentNetwork === "1" ? (
                    // Show Free Airdrop button if wallet is connected and user is on the correct network
                    <button className="centered-button" onClick={handleFreeAirdrop}>Free Airdrop</button>
                ) : (
                    <button className="centered-button" onClick={handleConnectWallet}>
                        {walletConnected ? "Change Network" : "Connect Wallet"}
                    </button>
                )
            )}
        </div>
    );
}

export default HomeComponent;